/*Custom path of fontawesome*/
$lib-path: "/assets/lib";
$fa-font-path: "#{$lib-path}/fontawesome/fonts" !default;
@import "utils/custom-bootstrap";
/* ======= Function - start ======= */
@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

@function rem($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

/* ======= Function - end ======= */
/* ======= Mixin - start ======= */
@mixin padding($name, $px) {
  //@debug unit($px);
  //@debug #{$name};

  &t-#{$name} {
    //@debug unit($px) == '%';
    @if (unit($px) == '%') {
      padding-top: $px !important;
    } @else {
      padding-top: rem($px) !important;
    }
  }
  &b-#{$name} {
    @if (unit($px) == '%') {
      padding-bottom: $px !important;
    } @else {
      padding-bottom: rem($px) !important;
    }
  }
  &l-#{$name} {
    @if (unit($px) == '%') {
      padding-left: $px !important;
    } @else {
      padding-left: rem($px) !important;
    }

  }
  &r-#{$name} {
    @if (unit($px) == '%') {
      padding-right: $px !important;
    } @else {
      padding-right: rem($px) !important;
    }

  }
  &x-#{$name} {
    @if (unit($px) == '%') {
      padding-right: $px;
      padding-left: $px;
    } @else {
      padding-right: rem($px) !important;
      padding-left: rem($px) !important;
    }
  }
  &y-#{$name} {
    @if (unit($px) == '%') {
      padding-top: $px !important;
      padding-bottom: $px !important;
    } @else {
      padding-bottom: rem($px) !important;
      padding-top: rem($px) !important;
    }
  }
}

@mixin margin($name, $px) {
  &t-#{$name} {
    @if (unit($px) == "%") {
      margin-top: $px;
    } @else {
      margin-top: rem($px);
    }
  }
  &b-#{$name} {
    @if (unit($px) == "%") {
      margin-bottom: $px;
    }

    margin-bottom: rem($px);
  }
  &l-#{$name} {
    @if (unit($px) == "%") {
      margin-left: $px;
    }

    margin-left: rem($px);
  }
  &r-#{$name} {
    @if (unit($px) == "%") {
      margin-right: $px;
    }

    margin-right: rem($px);
  }
  &x-#{$name} {
    @if (unit($px) == "%") {
      margin-right: $px;
      margin-left: $px;
    }

    margin-right: rem($px);
    margin-left: rem($px);
  }
  &y-#{$name} {
    @if (unit($px) == "%") {
      margin-top: $px;
      margin-bottom: $px;
    }

    margin-bottom: rem($px);
    margin-top: rem($px);
  }
}

/* ======= Mixin - end ======= */

/*Default Browser font-size*/
$base-font-size: 16px;
$base-font-size-sm: 23px;
/*======= Break points - start =======*/
// Extra large devices (large desktops, 1200px and up)
$xl: 1200px;
// Large devices (desktops, 992px and up)
$lg: 992px;
// Medium devices (tablets, 768px and up)
$md: 768px;
// Small devices (mobile, 576px and up)
$sm: 576px;
/*======= Break points - end =======*/
$ywm-blue-color: #2b9ceb;
$ywm-blue-color-2: #31a2f2;
$ywm-blue-color-3: #2898e6;
$ywm-black-color: #3c3c3c;
$ywm-black-color-2: #424242;
$ywm-white-color: #ffffff;
$ywm-white-color-2: #f0f0f0;
$ywm-white-color-3: #fafafa;
$ywm-gray-color: #e6e6e6;
$ywm-yellow-color: #f6db22;
$ywm-default-font-size: 1rem;
$ywm-5-rem: 5rem;
$ywm-3-rem: 3rem;
$ywm-18-px: rem(18);
$ywm-33-px: rem(33);
$ywm-24-px: rem(24);
$ywm-27-px: rem(27);
$ywm-28-px: rem(28);
$ywm-40-px: rem(40);
$ywm-58-px: rem(58);

%slash-prototype-shared {
  color: #ffffff;
  width: 2rem;
  height: 1.1rem;
  margin: 0;
  transform: rotate(-35deg);
}

%ywm-divider-shared {
  content: '';
  -webkit-box-flex: 1;
  flex-grow: 1;
  border-top: 1px solid #ffffff;
}

%ywm-16-24 {
  font-size: rem(16);
  line-height: em(24);
}

%ywm-16-34 {
  font-size: rem(16);
  line-height: em(34);
}

%ywm-16-36 {
  font-size: rem(16);
  line-height: em(36);
}

%ywm-18-33 {
  font-size: rem(18);
  line-height: em(33, 18);
}

%ywm-22-36 {
  font-size: rem(22);
  line-height: em(36, 22);
}

%ywm-22-70 {
  font-size: rem(22);
  line-height: em(70, 22);
}

%ywm-27-34 {
  font-size: rem(27);
  line-height: em(34, 27);
}


%default-prototype-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

%flex-context-full-support {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

html, body {
  background-color: transparent;
  z-index: -1;
  font-size: 16px;

  @media screen and (min-width: $lg) {
    font-size: 100%;
  }

  color: $ywm-black-color;
}

p {
  font-size: rem(18);
  margin-bottom: 0;
  line-height: 2.0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: inherit;
}

h3, .h3 {
  font-size: $ywm-27-px;
}

h1 strong, h1 b, h2 strong, h2 b, h3 strong, h3 b, h4 strong, h4 b, h5 strong, h5 b, h6 strong, h6 b, .h1 strong, .h1 b, .h2 strong, .h2 b, .h3 strong, .h3 b, .h4 strong, .h4 b, .h5 strong, .h5 b, .h6 strong, .h6 b {
  font-weight: 700;
  font-family: inherit;
}

ol {
  padding-left: 10px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

strong, b {
  font-family: inherit;
  font-weight: 700;
}

.text-white {
  color: $ywm-white-color !important;
}

.ywm-text-blue {
  color: $ywm-blue-color-2;
}

.ywm-text-yellow {
  color: $ywm-yellow-color;
}

.ywm-text-black {
  color: $ywm-black-color;
}

.ywm-btn-blue {
  color: #ffffff;
  background-color: $ywm-blue-color;
}

.ywm-btn-blue:hover {
  color: $ywm-black-color;
}

.ywm-btn-black {
  color: $ywm-black-color;
  background-color: $ywm-black-color;
}

.display {
  &-1, &-2, &-3, &-4 {
    line-height: 1.3;
  }
}

.ywm-right-divider, .ywm-left-divider, .ywm-divider {
  display: flex;
  align-items: baseline;
  -webkit-box-flex: 0;
  flex: 0;
  letter-spacing: .5px;
  margin: 3rem auto;
}

.ywm-right-divider::after {
  margin-left: .5rem;
  @extend %ywm-divider-shared;
}

.ywm-left-divider::before {
  margin-right: .5rem;
  @extend %ywm-divider-shared;
}

.ywm-divider {
  & ::after {
    margin-left: .5rem;
    @extend %ywm-divider-shared;
  }

  & ::before {
    margin-right: .5rem;
    @extend %ywm-divider-shared;
  }
}

.ywm {
  //#region =========== Custom margin - start - ===========
  &-m-x {
    margin-left: em(16);
    margin-right: em(16);

    @include media-breakpoint-up(xl) {
      margin-left: rem(255);
      margin-right: rem(255);
    }
  }

  &-m-l {
    margin-left: em(16);

    @media screen and (min-width: $lg) {
      margin-left: rem(255);
    }
  }

  &-mt {
    &-7 {
      margin-top: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-top: $ywm-5-rem + .7rem;
    }
  }

  &-mb {
    &-7 {
      margin-bottom: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-bottom: $ywm-5-rem + .7rem;
    }
  }

  &-mr {
    &-7 {
      margin-right: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-right: $ywm-5-rem + .7rem;
    }
  }

  &-ml {
    &-7 {
      margin-left: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-left: $ywm-5-rem + .7rem;
    }
  }

  &-my {
    &-7 {
      margin-top: $ywm-3-rem + .7rem;
      margin-bottom: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-top: $ywm-5-rem + .7rem;
      margin-bottom: $ywm-5-rem + .7rem;
    }
  }

  &-mx {
    &-7 {
      margin-right: $ywm-3-rem + .7rem;
      margin-left: $ywm-3-rem + .7rem;
    }

    &-8 {
      margin-right: $ywm-5-rem + .7rem;
      margin-left: $ywm-5-rem + .7rem;
    }
  }

  &-mx-8_2 {
    margin-right: $ywm-5-rem + .2rem;
    margin-left: $ywm-5-rem + .2rem;
  }

  &-mt-8_2 {
    margin-top: $ywm-5-rem + .2rem;
  }

  &-mb-8_2 {
    margin-bottom: $ywm-5-rem + .2rem;
  }

  &-mr-8_2 {
    margin-right: $ywm-5-rem + .2rem;
  }

  &-ml-8_2 {
    margin-left: $ywm-5-rem + .2rem;
  }

  &-my-8_2 {
    margin-top: $ywm-5-rem + .2rem;
    margin-bottom: $ywm-5-rem + .2rem;
  }

  &-mx-8_2 {
    margin-right: $ywm-5-rem + .2rem;
    margin-left: $ywm-5-rem + .2rem;
  }

  &-mt-p-1 {
    margin-top: 17%;
  }

  //#endregion

  //#region =========== Custom padding - start - ===========
  &-p {
    &-x {
      //padding-left: em(16);
      //padding-right: em(16);

      @include media-breakpoint-up(xl) {
        padding-left: 13.4%;
        padding-right: 13.4%;
      }
    }

    &-l {
      padding-left: em(16);

      @media screen and (min-width: $lg) {
        padding-left: rem(255);
      }
    }

    @include padding('6', 35px);
    @include padding('7', 48px);
    @include padding('8', 61px);
    @include padding('155_px', 155px);
    @include padding('10_42-per', 10.42%);

    &t-5 {
      padding-top: $ywm-28-px;
    }

    &b-5 {
      padding-bottom: $ywm-28-px;
    }

    &l-5 {
      padding-left: $ywm-28-px;
    }

    &r-5 {
      padding-right: $ywm-28-px;
    }

    &t-19_rem {
      padding-top: 19rem;
    }

    &b-19_rem {
      padding-bottom: 19rem;
    }

    &l-19_rem {
      padding-left: 19rem;
    }

    &r-19_rem {
      padding-right: 19rem;
    }

    &t-27 {
      padding-top: 27%;
    }

    &b-27 {
      padding-bottom: 27%;
    }

    &r-27 {
      padding-right: 27%;
    }

    &l-27 {
      padding-left: 27%;
    }
  }
;

  &-font-22 {
    font-size: $ywm-default-font-size + .4rem;
  }

  //#endregion

  //#region =========== Custom line height - start - ===========

  &-lh-1 {
    line-height: 1.1rem;
  }

  &-lh-2 {
    line-height: 2.25rem;
  }

  &-lh-3 {
    line-height: 3.5rem;
  }

  //#endregion

  //#region =========== Custom height - start - ===========

  &-h-35 {
    height: 35%;
  }

  &-h-50vh {
    min-height: 50vh;
    height: auto;
  }

  &-h-70vh {
    min-height: 70vh;
    height: auto;
  }

  //#endregion

  //#region =========== Other customize - start- ===========
  &-section-header {
    margin-bottom: 4rem;

    .ywm-title {
      text-transform: uppercase;
    }
  }

  &-vertically-line {
    &::before {
      content: '';
      border: 1px solid $ywm-white-color-2;
      height: 4rem;
      position: absolute;
      z-index: 100;
      right: 0;
      top: 30%;
    }
  }

  &-card {
    .card-title {
      font-weight: 700;
      font-size: $ywm-27-px;
      //margin-bottom: 3.25rem;
    }

    .card-subtitle {
      font-size: $ywm-18-px;
      //margin-top: -3.25rem + .625rem;
      //margin-bottom: 0.9375rem;
      font-weight: 700;
    }

    .card-body {
      font-size: 1rem;
    }

    .card-footer {
      font-size: rem(18, 16);
      padding: em(40, 18) 1.25rem;
    }
  }

  &-header-title {
    font-size: rem(48);
    line-height: em(70, 48);

    @include media-breakpoint-up(lg) {
      font-size: rem(58);
      line-height: em(70, 58);
    }

    & > h1 {
      font-size: rem(48);
      line-height: em(70, 48);

      @include media-breakpoint-up(lg) {
        font-size: rem(58);
        line-height: em(70, 58);
      }
    }
  }

  &-header-desc {
    @extend %ywm-18-33;

    & > p {
      @extend %ywm-18-33;
    }
  }

  &-main-p {
    @extend %ywm-16-36;

    & > p {
      @extend %ywm-16-36;
    }
  }

  &-desc {
    @extend %ywm-16-24;

    &>p {
      @extend %ywm-16-24;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 3rem;
  }

  &-bg-blue {
    background-color: $ywm-blue-color;
  }

  &-bg-blue-2 {
    background-color: $ywm-blue-color-2;
  }

  &-bg-white-3 {
    background-color: $ywm-white-color-3;
  }

  &-map-marker {
    &::after {
      content: '';
      @extend %default-prototype-bg;
      background-image: url("/assets/images/views/landing/ywm/icons/ic_location_pin.svg");
      position: relative;
      width: 22px;
      height: 27px;
      display: block;
    }
  }

  &-lead-1 {
    @extend %ywm-22-36;

    &>p {
      @extend %ywm-22-36;
    }
  }

  &-btn-round {
    //@extend .btn-round;
    outline: none;
  }

  &-input-round {
    border-radius: 50%;
  }

  &-round-box {
    border-radius: 10rem;
  }

  &-form-control {
    height: auto;

    @include media-breakpoint-up(lg) {
      height: calc(1.9em + 0.75rem + 2px);
    }
  }

  &-accordion {
    .card {
      .card-title {
        color: $ywm-blue-color-2;
        background-color: $ywm-gray-color;
        //border-bottom-color: $ywm-blue-color-2;
      }
    }
  }

  // Customize Jquery Steps
  &-wizard {
    // For common steps
    .steps {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        overflow-x: scroll;
      }

      &>ul {
        display: inline-flex;

        &>li {
          &.current {
            a {
              color: $ywm-blue-color-2;
            }
          }

          &.done {
            a {
              color: $ywm-black-color;
            }
          }

          a {
            color: $ywm-gray-color;
          }
        }
      }

      .number {
        background-color: #fff;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -24px;
        z-index: 10;
        text-align: center;
      }
    }
    // For vertical steps
    &.vertical {
      .steps {
        &>ul {
          display: block;

          &>li {
            display: block;
            width: 100%;
          }
        }
      }
    }
    // For circle steps
    &-circle {
      .steps {
        .current, .disabled, .last, .first {
          &>a {
            background: transparent;
          }
        }

        &>ul {
          &>li {
            display: table-cell;
            width: auto;
            vertical-align: top;
            text-align: center;
            position: relative;

            // Customer for done step
            &.done {
              a {
                background-color: transparent;
                color: $ywm-black-color;
              }

              .number {
                color: $ywm-white-color;
                border-color: $ywm-blue-color-2;
                background-color: $ywm-blue-color-2;
              }
            }

            // For current step
            &.current {
              a {
                .number {
                  border-color: $ywm-blue-color-2;
                }
              }
            }

            a {
              pointer-events: none;
              position: relative;
              padding-top: 52px;
              margin-top: 20px;
              margin-bottom: 20px;
              display: block;

              .number {
                width: 50px;
                height: 50px;
                line-height: 45px;
                border: 3px solid #F3F3F3;
                font-size: 1.3rem;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    &-content {
      height: auto;
      min-height: 300px;
    }
  }

  // Customize Dropdown menu
  &-dropdown-menu {

    & > .lang-row-wrapper {
      display: flex;
      padding: 0px 16px;

      & > span {
        text-align: center;
      }

      & > .dropdown-item {
        color: $ywm-black-color;
        width: fit-content;
        margin: auto;

        &.active {
          background-color: transparent;
          color: $ywm-blue-color-2;
        }
      }

      & > .dropdown-item:hover {
        color: $ywm-blue-color-2;
      }
    }



  }

  //#endregion


}

.slash {
  &::before {
    content: "";
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: em(20);
    @extend %slash-prototype-shared;
  }

  &-blue {
    &::before {
      border-top: 2px solid $ywm-blue-color-2;
    }
  }

  &-yellow {
    &::before {
      border-top: 2px solid $ywm-yellow-color;
    }
  }

  &-white {
    &::before {
      border-top: 2px solid $ywm-white-color;
    }
  }

  &-right {
    &::before {
      margin-left: auto;
    }
  }

  &-left {
    margin-right: auto;
  }
}

.nav {
  &.ywm-border-l {
    .nav-link {
      border-left: 3px solid $ywm-gray-color;
      padding-left: 1.5em;

      &.active {
        color: $ywm-blue-color-2;
        border-left-color: $ywm-blue-color-2;
      }
    }
  }

  &-tabs-minimal {
    .nav-item {
      .nav-link {
        &.active {
          color: $ywm-blue-color-2;
          border-bottom-color: $ywm-blue-color-2;
        }
      }
    }
  }
}

.list-style-none {
  list-style: none;
}

//#region =========== Animation - start- ===========
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 $ywm-white-color-2;
  }

  100% {
    box-shadow: 0 0 0 95px rgba(0, 0, 0, 0);
  }
}

//#endregion