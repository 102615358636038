// Small devices (landscape phones, 360px and down)
@media (max-width: $sm) {
  #solution-list-two {
    .ywm-bg-solution {
      height: 100%;
      width: 100%;
    }
    .solution-wrapper {
      .solution-item {
        .solution-info {
          right: 0;
          &.order-lg-first {
            left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: $md) {
  #solution-list-two {
    .ywm-bg-solution {
      height: 100%;
      width: 100%;
    }
    .solution-wrapper {
      .solution-item {
        .solution-info {
          right: 0;
          &.order-lg-first {
            left: 0;
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $lg) {
  .nav-navbar {
    .nav-link {
      font-size: rem(16);
    }
  }
}