@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap');
@import "../../common";
@import "../../utils/nice-select/nice-select.scss";


@font-face {
  font-family: 'themify';
  src: url('fonts/themify.eot');
  src: url('fonts/themifyd41d.eot') format('embedded-opentype'),
  url('fonts/themify.woff') format('woff'),
  url('fonts/themify.ttf') format('truetype'),
  url('fonts/themify.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icons-prototype-shared {
  position: relative;
  width: 100%;
  height: 3rem;
  background-size: contain;
}

html, body {
  font-family: "Noto Sans KR", sans-serif;

  &:lang(vi) {
    //font-family:  'Playfair Display', serif;
    font-family: 'Roboto Slab', serif;
  }
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: 1920px;
    padding-left: rem(255);
    padding-right: rem(255);
  }
}

.container-fluid {
  @include media-breakpoint-up(xl) {
    max-width: 1920px;
  }
}

.header {
  & > .container-fluid {
    &.ywm-p-x {
      margin: auto;
    }
  }
}

//.h-fullscreen, .h-100vh {
//  height: auto;
//
//  @media screen and (max-height: 640px) {
//    height: ;
//  }
//}

.card, .navbar {
  box-shadow: none;
}

.navbar-stick-dark.stick .logo-dark {
  display: inline-flex;
  align-items: center;
}

.logo-dark h1 {
  margin: 0;
}

#footer-constellation {
  position: relative;

}

.show-on-map {
  border-bottom: 1px dashed;
}

.find-us-bg {
  background: rgba(255, 255, 255, 0.2);
}

body > .footer .nav a:last-child {
  display: block;
}

.blockquote {
  word-break: break-all;
}

.w-220 {
  width: calc(0% + 220px);
}

.section {
  @include media-breakpoint-down('md') {
    padding-top: rem(95, 14);
  }
}

.ywm-btn-outline-black {
  color: $ywm-black-color;
  border: 1px solid $ywm-black-color;
}

.ywm-header {
  padding-top: (222px / $base-font-size) + 0rem;
}

.form-group {
  margin-bottom: rem(30);

  .text-danger {
    padding-top: rem(16);
  }
}

%ywm-nav-link {
  font-size: rem(20, 14);
  line-height: em(28, 20);
  font-weight: 400;

  @include media-breakpoint-up(lg) {
    font-size: rem(16);
    line-height: em(26);
    font-weight: 400;
  }
}

.navbar-expand-lg {
  .nav-navbar:not(.nav-inline) {
    & > .nav-link {
      @extend %ywm-nav-link
    }

    .nav-item {
      > .nav-link {
        @extend %ywm-nav-link
      }
    }
  }

  .nav-navbar {
    & .nav .nav-link {
      font-size: rem(14, 14);
      line-height: em(22, 14);
      font-weight: 400;
    }
  }
}

.navbar {
  min-height: 90px;
  top: 0;
  @include media-breakpoint-up(xl) {
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    top: 0;
  }

  &-right {
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }
  }

  .nav-navbar {
    > .nav-link.active {
      border-bottom: 3px solid $ywm-blue-color-2;
      border-top: none;
      color: $ywm-blue-color;

      @include media-breakpoint-up(lg) {
        padding-top: calc(1.71875em - 3px);
        border-top: 3px solid $ywm-blue-color-2;
        border-bottom: none;
      }
    }

    > .nav-link {
      font-weight: 400;
      padding-left: 1.125rem;
      padding-right: 1.125rem;

      @include media-breakpoint-up(lg) {
        padding: em(27.5) em(17.5) em(27.5) em(17.5);
      }

      @include media-breakpoint-up(xl) {
        padding: em(27.5);
      }

      @media (min-width: 1200px) and (max-width: 1500px) {
        padding-left: em(11.5);
        padding-right: em(11.5);
      }
    }

    .nav-item {
      & > .nav-link {
        color: $ywm-white-color;
      }

      padding-left: rem(10);
      padding-right: rem(10);

      @include media-breakpoint-up(xl) {
        padding: em(27.5) 0;
      }

      @include media-breakpoint-up(lg) {
        padding: em(27.5) 0;
      }
    }
  }

  .nav {
    .nav-link {
      color: $ywm-white-color;
    }

    .nav-link.active {
      color: $ywm-blue-color-2 !important;

    }

    .nav-link:hover {
      color: $ywm-blue-color-2;
    }
  }

  &.navbar-light {
    color: $ywm-white-color;

    & .navbar-toggler {
      color: rgba(255, 255, 255, 0.65);
    }

    .ywm-dropdown-menu {
      background-color: rgba(255, 255, 255, 0.8);

      & > .lang-row-wrapper:hover {
        background-color: $ywm-white-color;
      }

    }

    .nav-navbar {
      & > .nav-item {
        & > .nav-link {
          color: $ywm-white-color;
        }
      }
    }
  }

  &.navbar-dark {
    color: $ywm-black-color;
    border-bottom: 1px solid $ywm-gray-color;

    & .navbar-toggler {
      color: rgba(117, 117, 117, 0.8);
    }

    .nav-navbar {
      > .nav-link {
        color: $ywm-black-color;
      }

      & > .nav-item {
        & > .nav-link {
          color: $ywm-black-color;
        }
      }
    }

    .nav {
      > .nav-link {
        color: $ywm-black-color;
      }
    }
  }

  &.navbar-stick-dark {
    & .nav-navbar {
      & > .nav-item {
        & > nav.nav {
          margin-top: em(13.5);
          background-color: white;

          & > .nav-item {
            & > .nav-link {
              color: $ywm-black-color;
            }
          }

          .nav-link {
            &:hover {
              color: $ywm-blue-color-2
            }

            color: $ywm-black-color;
          }
        }
      }
    }

    &.stick {
      color: $ywm-black-color;

      .nav-navbar,
      .nav {
        & > .nav-item {
          & > .nav-link {
            color: $ywm-black-color;
          }
        }

        > .nav-link {
          color: $ywm-black-color;
        }
      }
    }
  }

  &.navbar-stick-blue {
    .nav {
      & > .nav-link {
        color: $ywm-white-color;

        &.active {
          color: $ywm-black-color !important;
        }
      }
    }

    .nav-navbar {
      & > .nav-link {
        &.active {
          color: $ywm-white-color;
          border-top: 3px solid $ywm-white-color;
        }
      }

      & > .nav-item {
        & > nav.nav {
          & > .nav-item {
            & > .nav-link {
              color: $ywm-black-color;
            }
          }

          .nav-link {
            &:hover {
              color: $ywm-blue-color-2
            }

            color: $ywm-black-color;
          }
        }
      }
    }

    &.stick {
      color: $ywm-black-color;

      .nav-navbar,
      .nav {
        & > .nav-item {
          & > .nav-link {
            color: $ywm-black-color;
          }
        }

        > .nav-link {
          color: $ywm-black-color;

          &.active {
            color: $ywm-blue-color-2 !important;
          }
        }
      }

      .nav-navbar {
        > .nav-link.active {
          color: $ywm-blue-color-2 !important;
        }
      }

      .logo-dark {
        display: inline-block;
      }

      .logo-light {
        display: none;
      }

      .navbar-toggler {
        color: $ywm-black-color;
      }
    }
  }
}

.navbar-open {
  .navbar-mobile {
    .nav-navbar, .nav {
      & > .nav-item {
        & > .nav-link {
          color: $ywm-black-color;
        }
      }

      & > .nav-link {
        color: $ywm-black-color;

        &.active {
          color: $ywm-blue-color-2 !important;
        }
      }
    }
  }
}

.languages-wrapper {
  display: none;

  &-mobile {
    display: flex;
    align-items: center;
    font-size: rem(20, 14);
    line-height: em(28, 20);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.text-black {
  color: $ywm-black-color;
}

.footer {
  border-top: 1px solid $ywm-black-color;
}

.ywm-scroll-top-btn {
  background-color: rgba(41, 41, 41, 0.4);
  color: $ywm-blue-color-2;
}

.ywm-scroll-top-btn:hover {
  color: $ywm-blue-color-2;
}

.ywm-iconbox {
  font-size: 2em;
  background-color: $ywm-white-color;
  opacity: 0.1;
  color: $ywm-blue-color-2;
  border: none;

  &-modal {
    font-size: 2em;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 1;
    color: $ywm-blue-color-2;
    border: none;
    height: 66px;
    width: 66px;
  }
}

.slick-list,
.slick-track {
  height: 100%;
}

.slider-center-focused .slick-slide {
  opacity: 1;
}

.slide {
  &--1 {
    background-image: url("/assets/images/views/landing/ywm/banner/banner-1.jpg");
    @extend %default-prototype-bg;

    &.replace {
      background-image: url("/assets/images/views/landing/ywm/banner/banner-1-tiny.jpg");
    }
  }

  &--2 {
    background-image: url("/assets/images/views/landing/ywm/banner/banner-2.jpg");
    @extend %default-prototype-bg;
  }

  &--3 {
    background-image: url("/assets/images/views/landing/ywm/banner/banner-3.jpg");
    @extend %default-prototype-bg;
  }
}

.slick-initialized .slick-slide {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

#home-banners {
  & > .swiper-wrapper {
    & > .swiper-slide {
      display: flex;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    width: 4rem;
    height: 4rem;
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 1;
  }

  .swiper-button-next {
    right: rem(16);
    background-image: url("/assets/images/views/landing/ywm/icons/ic-next.png");
  }

  .swiper-button-prev {
    left: rem(16);
    background-image: url("/assets/images/views/landing/ywm/icons/ic-prev.png");
  }

  .swiper-button-disabled {
    opacity: .3;
  }
}

#banners-bg {
  position: absolute;
  top: 0;

  .slick-dots {
    position: relative;
    display: none;

    & > li {
      display: none;

      &.slick-active {
        display: inline-flex !important;
      }
    }
  }

  .slider-scroll {
    text-align: center;
    display: none;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1000;
    bottom: 20%;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    .scroller {
      animation: scrollDown5 1.6s infinite;
    }
  }
}

.scroll-down {
  @extend %flex-context-full-support;
  color: $ywm-white-color;
  text-align: center;
  flex-direction: column;
  position: relative;

  .scroller {
    @extend %flex-context-full-support;
    flex-direction: column;
    animation: scrollDown5 1.6s infinite;

    &::after {
      content: 'Scroll';
      color: $ywm-white-color;
    }
  }
}

.ywm-slider-arrows-circle {
  %arrows-circle-shared {
    width: 4rem;
    height: 4rem;
    background-color: rgba(255, 255, 255, 0.2);
    color: $ywm-blue-color-2;
    border-radius: 10rem;
    opacity: 1;
  }

  & > .slick-next {
    @extend %arrows-circle-shared;
    right: 1em;
  }

  & > .slick-prev {
    @extend %arrows-circle-shared;
    left: 1em;
  }

  & > .slick-next:hover,
  .slick-prev:hover {
    background-color: rgba(255, 255, 255, .5);
  }
}

.content-banner {
  align-items: center;

  .ywm-header-title {
    line-height: em(70, 58)
  }
}

@mixin ywm-button($bgColor, $textTransform) {
  background-color: $bgColor;
  border-radius: 0;
  text-transform: $textTransform;
  font-size: rem(22);
  width: 100%;
  //height: 4.375rem;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 2%, 90% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0, 100% 2%, 90% 100%, 0% 100%);

  @include media-breakpoint-up(md) {
    max-width: em(284, 22);
    width: 100%;
  }
}

.ywm-btn {
  @include ywm-button($ywm-blue-color-2, capitalize);

  &-sm {
    @include ywm-button($ywm-blue-color-2, capitalize);
    font-size: rem(16);
    line-height: em(40);
    font-weight: 400;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  &-lg {
    @include ywm-button($ywm-blue-color-2, capitalize);
    @extend %ywm-22-70;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}

.see-more-section {
  text-align: center;
  z-index: 0;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  & > button:nth-last-child(1) {
    @include ywm-button($ywm-blue-color-2, capitalize);
    margin-top: em(136, 22);
    padding: 0;
  }
}

#vision-section {
  color: $ywm-white-color;
}

.ywm-vision-card {
  background-color: transparent;

  .card-body {
    height: auto;
    color: $ywm-black-color;
    background-color: $ywm-white-color;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    position: relative;
    bottom: 4rem;
    box-shadow: 0 11px 12px -3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 11px 12px -3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 11px 12px -3px rgba(0, 0, 0, 0.5);
    font-size: rem(18);
    line-height: 1.5em;

    @include media-breakpoint-up(lg) {
      min-height: em(500, 18);
    }

    @include media-breakpoint-up(xl) {
      min-height: em(311, 18);
    }

    p {
      cursor: default;
      font-size: 1em;
      line-height: ($ywm-default-font-size * 2) + .2rem;
    }
  }

  .card-body:hover {
    transition: all 0.3s;
    color: $ywm-white-color;
    background-color: $ywm-blue-color-2;
  }

  .card-title {
    font-weight: 700;
    //font-family: "Noto Sans CJ Kkr Bold";
    font-family: inherit;
  }

  //& :hover {
  //  transition: all 0.3s;
  //  color: $ywm-white-color;
  //  background-color: $ywm-blue-color-2;
  //}

}

.feature-left {
  @extend %flex-context-full-support;
  flex-direction: column;
}

.feature-img {
  padding: 50px 0;
  width: 60%;

  & :nth-child(1) {
    padding-top: 0;
  }

  & :nth-last-child(1) {
    padding-bottom: 0;
  }
}

.feature-right-item {
  width: 100%;
  align-items: center;
  font-size: rem(16);
  height: auto;
  flex-wrap: wrap;
  padding-top: em(10);
  padding-bottom: em(10);
  border-bottom: 1px solid $ywm-white-color-2;
  @extend %flex-context-full-support;

  @media screen and (min-width: $xl) {
    height: em(160);
  }

  > img {
    width: rem(80);
    height: rem(80);
  }

  .feature-item-info {
    padding: em(32) 0;
    width: 70%;

    .feature-name {
      text-transform: capitalize;
      color: $ywm-blue-color-2;
    }

    .feature-desc {
      color: $ywm-black-color;
      font-size: em(16);
    }
  }
}

.ywm-i-prev {
  @extend %icons-prototype-shared;
  background: url('/assets/images/views/landing/ywm/icons/ic-prev.png') no-repeat center center;
}

.ywm-i-next {
  @extend %icons-prototype-shared;
  background: url("/assets/images/views/landing/ywm/icons/ic-next.png") no-repeat center center;
}

.ywm-slash {
  border-top: 2px solid $ywm-blue-color-2;
  @extend %slash-prototype-shared;

  &-yellow {
    border-top: 2px solid $ywm-yellow-color;
    @extend %slash-prototype-shared;
  }

  &-white {
    border-top: 2px solid $ywm-white-color;
    @extend %slash-prototype-shared;
  }
}

.service {
  &-item {
    cursor: pointer;
    padding: 1rem 2rem;
    margin: 0;
    height: auto;
    min-height: 496px;
    @extend %flex-context-full-support;
    flex-direction: column;
    justify-content: space-between;

    .services-title {
      font-weight: 700;
      text-transform: uppercase;
    }

    .service-hash-tag {
      font-size: rem(13, 16);
      line-height: em(20, 13);

      p {
        font-size: rem(13, 16);
        line-height: em(20, 13);
      }
    }
  }

  &::after {
    content: " ";
    transition: all 0.5s linear;
    width: 0;
    bottom: -30px;
    left: 0;
    position: absolute;
    border-bottom: 2px solid $ywm-blue-color-2;
  }

  &:hover::after {
    width: 80%;
  }

  &-1 {
    background-color: rgb(248, 248, 248);
    background-image: url("/assets/images/views/landing/ywm/service-1.png");
    background-blend-mode: multiply;
    @extend %default-prototype-bg;
    background-size: contain;
    background-position-x: right;
  }

  &-2 {
    color: $ywm-white-color;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("/assets/images/views/landing/ywm/service-2.png");
    background-blend-mode: multiply;
    @extend %default-prototype-bg;
  }

  &-3 {
    color: $ywm-white-color;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("/assets/images/views/landing/ywm/service-3.png");
    background-blend-mode: multiply;
    @extend %default-prototype-bg;
  }

  &-4 {
    color: $ywm-white-color;
    background-image: url("/assets/images/views/landing/ywm/service-4.png");
    @extend %default-prototype-bg;
  }

  &-5 {
    color: $ywm-white-color;
    background-image: url("/assets/images/views/landing/ywm/electric-car.png");
    @extend %default-prototype-bg;
  }

  &-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    height: auto;
    display: block;
  }

  &-info {
    margin-top: auto;
    font-size: rem(16, 16);
    line-height: em(24);

    p {
      font-size: rem(16, 16);
      line-height: em(24);
    }
  }
}

.services {
  &-list {
    .shuffle-item {
      height: auto;
      padding: em(7) em(7);

      @include media-breakpoint-up(lg) {
        min-width: 330px;
      }

      &:nth-child(1) {
        padding-left: 0;
      }

      &:nth-last-child(1) {
        padding-right: 0;
      }
    }
  }
}

.services {
  height: 496px;
  border: none;
  width: 330px;
  @extend %flex-context-full-support;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;

  & > .container {
    height: 100%;
    @extend %flex-context-full-support;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem;
  }
}

.ywm-full-screen-modal {
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.75);

  &.show {
    display: flex !important;
  }

  .modal-dialog {
    height: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  .modal-content {
    margin: 0;
  }
}

@mixin ywm-custom-modal {
  .modal-content {
    background: transparent;
  }

  .modal-header {
    border: none;

    @include media-breakpoint-up(lg) {
      //padding: em(82) em(93);
      padding: 6.3% 4.84%
    }
  }

  .modal-body {
    padding: rem(60, 12) rem(29, 12);

    .modal-info {
      padding-top: rem(90, 12);
      @include media-breakpoint-up(lg) {
        padding-top: 10.0625rem;
      }

      .ywm-title {
        line-height: 1.2;
      }
    }

    .modal-image {
      //padding-top: 7%;
    }
  }
}

%bg-clip-path-parallelogram-shared {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  -webkit-clip-path: polygon(63% 0, 100% 0%, 100% 100%, 43% 100%);
  clip-path: polygon(64% 0, 100% 0%, 100% 100%, 43% 100%);
}

#modal-chauffeur {
  @include ywm-custom-modal;

  .modal-body {
    padding: rem(28) rem(28);

    @include media-breakpoint-up(xl) {
      padding: rem(45) 0 0 rem(254);
    }
  }

  .bg-chauffeur {
    @extend %bg-clip-path-parallelogram-shared;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      background-color: $ywm-yellow-color;
    }
  }

  .chauffeur-img {
    position: relative;
    top: 0;
    right: 0;
  }

  #chauffeur-slider {
    @include media-breakpoint-up(lg) {
      padding-top: rem(157);
    }

    .chauffeur-arrows {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        margin-top: rem(164);
      }
    }
  }

  #chauffeur-img-slider {
    .swiper-slide-active {
      @extend %flex-context-full-support;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

#modal-car-delivery {
  display: none;
  @include ywm-custom-modal;

  #contact-solution-wrapper {
    padding-top: rem(16);
    width: 50%;

    @include media-breakpoint-up(lg) {
      padding-top: 52.2%;
    }

    &.btn {
      font-size: rem(22);
      line-height: em(70, 22);
    }
  }
}

#modal-tourism-taxi {
  @include ywm-custom-modal;
  display: none;

  .modal-header {
    @include media-breakpoint-up(lg) {
      padding-right: 13%;
      padding-left: 13%;
    }
  }

  .modal-body {
    .modal-info {
      padding-top: rem(61, 12);
    }
  }

  .bg-tourism-taxi {
    @extend %bg-clip-path-parallelogram-shared;
    @include media-breakpoint-up(lg) {
      background-color: $ywm-blue-color-2;
    }
  }

  .modal-image {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .tourism-img {
    margin-left: rem(-29, 12);
  }
}

#modal-ai {
  @include ywm-custom-modal;
  display: none;

  .modal-body {
    padding: rem(28) rem(28);

    @include media-breakpoint-up(xl) {
      padding: rem(45) 0 0 rem(254);
    }
  }

  .modal-header {
    @include media-breakpoint-up(lg) {
      padding-right: 13%;
      padding-left: 13%;
    }
  }

  .bg-ai {
    @extend %bg-clip-path-parallelogram-shared;
    @include media-breakpoint-up(lg) {
      background-color: $ywm-blue-color-2;
    }
  }

  #phone-layout {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/mobile/phone-layout.png");
    background-size: contain;
    margin: auto;
    height: 505px;
    width: 251px;

    @include media-breakpoint-up(lg) {
      background-image: url("/assets/images/views/landing/ywm/phone-layout.png");
      margin-right: auto;
      margin-left: 0;
      height: 888px;
      width: 441px;
    }
  }

  #ai-img-slider {
    position: relative;
    top: 39px;
    max-width: 222px;

    @include media-breakpoint-up(lg) {
      top: 64px;
      max-width: 394px;
    }
  }

  #ai-slider-pagination {
    width: 100%;
    padding-top: rem(63);
    position: relative;

    & > .swiper-pagination-bullet {
      margin-left: rem(16);
      margin-right: rem(16);

      &-active {
        background-color: $ywm-blue-color-2;
      }
    }
  }

  #ai-detail-btn-wrapper {
    margin-top: rem(90);
    width: 60%;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  #ai-arrows {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}


#modal-eon {
  @include ywm-custom-modal;
  display: none;

  .modal-body {
    padding: rem(28) rem(28);

    @include media-breakpoint-up(xl) {
      padding: rem(45) 0 0 rem(254);
    }
  }

  .modal-header {
    @include media-breakpoint-up(lg) {
      padding-right: 13%;
      padding-left: 13%;
    }
  }

  .bg-ai {
    @extend %bg-clip-path-parallelogram-shared;
    @include media-breakpoint-up(lg) {
      background-color: $ywm-blue-color-2;
    }
  }

  #phone-layout {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/mobile/phone-layout.png");
    background-size: contain;
    margin: auto;
    height: 505px;
    width: 251px;

    @include media-breakpoint-up(lg) {
      background-image: url("/assets/images/views/landing/ywm/phone-layout.png");
      margin-right: auto;
      margin-left: 0;
      height: 888px;
      width: 441px;
    }
  }

  #eon-img-slider {
    position: relative;
    top: 39px;
    max-width: 222px;

    @include media-breakpoint-up(lg) {
      top: 64px;
      max-width: 394px;
    }
  }

  #ai-slider-pagination {
    width: 100%;
    padding-top: rem(63);
    position: relative;

    & > .swiper-pagination-bullet {
      margin-left: rem(16);
      margin-right: rem(16);

      &-active {
        background-color: $ywm-blue-color-2;
      }
    }
  }

  #ai-detail-btn-wrapper {
    margin-top: rem(90);
    width: 60%;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  #ai-arrows {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .eon-charging {
    width: 55%;
    padding: 5px 10px;
    font-size: 28px;
    line-height: 46px;
    color: #ffffff;
    border-radius: 26px;
    border: 1px solid #ffffff;
    margin-bottom: 16px;
  }
}

#download-app-content {
  @extend %flex-context-full-support;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: em(60, 10.2);

  @include media-breakpoint-up(lg) {
    margin-top: em(90);
  }

  & > a:nth-last-child(1) {
    margin-left: em(22);
  }
}

%ywm-ic-shared {
  display: block;
  width: 44px;
  height: 44px;
  max-width: 44px;
  max-height: 44px;
  position: relative;
  background-size: contain;
}

.ywn-ic-close {
  @extend %ywm-ic-shared;
  background-image: url("/assets/images/views/landing/ywm/icons/btn_close.svg");
}

.ywn-ic-close-white {
  @extend %ywm-ic-shared;
  background-image: url("/assets/images/views/landing/ywm/icons/btn_close_white.svg");
}

.bg-yw {
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/bg-yw.png");
  background-color: $ywm-blue-color-2;
}

#header-about-us {
  &.bg-about-us {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/about-us-bg.jpg");

    &.replace {
      background-image: url("/assets/images/views/landing/ywm/about-us-bg-tiny.jpg");
    }
  }

  .bg-yw {
    position: absolute;
    height: 100%;
    bottom: -6%;
    width: 100%;
    background-size: auto;
    background-position: center bottom;
  }
}

#contact-map {
  display: none;
}

#office-address {
  .card {
    cursor: pointer;
    height: 100%;

    .card-body {
      padding: em(40) em(30) em(0) em(30);

      .fa-map-marker {
        color: #cccccc;
        font-size: 1.375rem;
      }

      .card-title {
        margin-bottom: 4px;
      }
    }

    .card-footer {
      padding: em(25) em(30, 18) em(40) em(30, 18);
      border-top: none;
    }

    &:hover {
      color: $ywm-blue-color-3;

      .card-subtitle {
        color: $ywm-blue-color-2;
      }

      .fa-map-marker {
        color: $ywm-blue-color-2;
      }
    }

    &.selected {
      color: $ywm-blue-color-2;

      .card-subtitle {
        color: $ywm-blue-color-2;
      }
    }
  }

  .address {
    padding-top: em(7);
    font-size: rem(16);
    line-height: em(24);
  }

  #list-address-mobile {
    .swiper-pagination {
      padding-bottom: rem(2);
    }

    .swiper-pagination-bullet-active {
      background-color: $ywm-blue-color-2;
    }
  }
}

#contact-form {
  padding-bottom: rem(60);

  .card {
    padding: rem(40);

    .card-body {
      .form-info {
        margin-top: 4.0625rem;
        padding: 0;

        @include media-breakpoint-up(lg) {
          padding: 0 7%;
        }
      }
    }
  }
}

.input-line {
  .form-group {
    label {
      font-size: rem(16);
    }
  }

  .form-control {
    font-size: rem(16);
    font-weight: 400;
    background-size: 0 2px, 100% 2px;
    background-image: linear-gradient($ywm-blue-color-2, $ywm-blue-color-2), linear-gradient(#eaeff4, #eaeff4);

    &:focus {
      background-size: 100% 2px, 100% 2px;
    }

    &.ywm-input {
      font-size: rem(16);
      font-weight: 400;
      background-size: 0 2px, 100% 2px;
      padding: 2.0625rem 0;

      &:focus {
        color: $ywm-blue-color-2;
        background-size: 100% 2px, 100% 2px;
      }

      &[type="file"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;

        & + label {
          /* Style for "Location" */
          color: #bebebe;
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(36);
          position: relative;
          top: rem(-56px);
          @extend %flex-context-full-support;
        }
      }
    }

    &.ywm-select {
      height: 5rem;
      @extend %flex-context-full-support;
      background-size: 0 2px, 100% 2px;

      &:focus, :focus-within {
        background-size: 100% 2px, 100% 2px;
      }

      &:hover {
        border: none;
      }
    }

    &.ywm-textarea {
      border: 2px solid #e6e6e6;
      padding: $ywm-33-px $ywm-24-px;

      &:focus {
        color: $ywm-blue-color-2;
      }
    }
  }
}

.input-group-select {
  border: none;

  .input-group-prepend {
    padding-right: 0.3em;
  }
}

.nice-select {
  @extend %flex-context-full-support;
  align-items: center;
  min-width: 120px;

  .list {
    margin-top: 0;
    border-radius: 0;

    & > .selected {
      display: none;
    }
  }

  .option {
    line-height: calc(1.5em + 0.75rem + 4px);
    min-height: calc(1.5em + 0.75rem + 4px);
  }

  &.open {
    border: none;

    & > .current {
      color: $ywm-blue-color-2;
    }

    &::before {
      //content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #0b0b0b;
      top: 0;
      right: 0;
      z-index: 1;
      opacity: 0.7;
    }

    &:after {
      color: $ywm-blue-color-2;
    }
  }

  &:after {
    font-family: FontAwesome;
    content: '\f0d7';
    font-weight: 900;
    border: none;
    transform: none;
    //margin-top: -2%;
    height: 13px;
    width: 14px;
    @extend %flex-context-full-support;
    align-items: center;
    padding-left: 3em;
    //justify-content: flex-end;
  }

  &.open:after {
    transform: none;
    font-family: FontAwesome;
    content: '\f0d8';
  }
}

/* Time Line - start */
#ywm-time-line {
  margin-top: 7.0625rem;
}

.ywm-custom-timeline {
  //padding: 0 0;
  padding: 0 rem(16);

  @include media-breakpoint-up(xl) {
    padding: 0 13%;
    max-width: 1920px;
  }

  &::before {
    top: 0;
    left: 50%;

    @include media-breakpoint-up(md) {
      top: 30%;
      left: 0;
    }
  }

  .dot {
    position: relative;
    width: 100%;
    @extend %flex-context-full-support;
    justify-content: center;
    //padding: 1rem 0;

    @include media-breakpoint-up(md) {
      width: 6%;
    }

    &::before {
      content: '';
      position: relative;
      right: 0;
      top: calc(29% + 2px);
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      background-color: $ywm-blue-color-2;
    }
  }

  .timeline-item {
    .year {
      position: relative;
      top: -9.25rem;
      color: $ywm-blue-color-2;
    }

    &::before {
      width: 13.1875rem;
      height: 13.1875rem;
    }

    &:nth-last-child(1) {
      &::before {
        background: $ywm-blue-color-2;
        animation: shadow-pulse 1s infinite;
      }

      .year {
        color: $ywm-white-color;
      }

      color: $ywm-blue-color-2;
    }
  }

  &.timeline-horizontal {
    &::before {
      background-color: $ywm-white-color-2;
    }

    .timeline-item {
      padding: 2.5rem 0;
      // margin-top: 145px;
      text-align: center;

      @include media-breakpoint-up(lg) {
        width: 20.3%;
      }

      @include media-breakpoint-up(xl) {
        width: 18.3%;
        min-width: 211px;
      }

      &::before {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

/*Time line - end*/

//#region ======= Solutions Page - start =======

#header-solutions {
  &.header {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/bg-solution.jpg");

    &.progressive--not-loaded {
      background-image: url("/assets/images/views/landing/ywm/bg-solution-tiny.jpg");
    }
  }
}


#solution-list-one {
  padding: 5.625rem 0;

  .solution-wrapper {
    .solution-item {
      //display: flex;
      //flex-direction: column;
      //justify-content: space-around;
      border-right: 1px solid $ywm-white-color-2;
      padding-left: $ywm-40-px;
      padding-right: $ywm-40-px;

      &:nth-last-child(1) {
        border-right: none;
        padding-right: 0;
      }

      & > .solution-title {
        @extend %flex-context-full-support;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;
        height: auto;
        min-height: 200px;
      }
    }
  }
}

#solution-list-two {
  padding: 11.25rem 0;

  .ywm-bg-solution {
    position: absolute;
    height: 100%;
    width: 59%;
    margin-top: -11rem;
  }

  .solution-wrapper {
    .solution-item {
      @extend %flex-context-full-support;
      justify-content: center;
      padding-bottom: 7.5rem;

      @include media-breakpoint-up(ywm_1700) {
        justify-content: flex-start;
      }

      &:nth-last-child(1) {
        padding-bottom: 0;
      }

      .solution-img-wrapper {

      }

      .solution-info {
        width: 100%;
        position: relative;
        top: rem(-100);
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-up(lg) {
          width: 70%;
        }

        @include media-breakpoint-up(ywm_1700) {
          right: auto;
          top: 0;
          left: rem(-120);
          width: 43%;
        }

        & > .card {
          box-shadow: 0 3px 13px rgba(0, 0, 0, 0.14);
          min-height: 20.625rem;
          max-width: 630px;
          margin: auto;

          & > .card-body {
            @extend %flex-context-full-support;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            font-size: rem(16);
            padding: rem(61) rem(59) rem(59) rem(59);

            .solution-info-title {
              @extend %ywm-27-34;
            }

            .solution-info-desc {
              @extend %ywm-16-24;

              & > p {
                @extend %ywm-16-24;
              }
            }
          }
        }
      }

      &.solution-right {
        .solution-info {
          @include media-breakpoint-up(ywm_1700) {
            justify-content: flex-end;
            order: -1;
            right: rem(-120);
            left: auto;
          }
        }
      }
    }
  }
}

//#endregion

//#region ======= Careers Page - start =======

#btn-open-position {
  max-width: (284px / $base-font-size) + 0rem;
  font-weight: 400;
  color: $ywm-blue-color-2;
  background-color: $ywm-white-color;
}

.careers-bg {
  &::before {
    content: 'careers';
    text-transform: uppercase;
    height: 1px;
    width: 1px;
    color: $ywm-blue-color-3;
    font-family: "Noto Sans CJ Kkr Black", sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    top: 35%;
    @extend %flex-context-full-support;
    padding-left: 0.4375rem;
    position: static;

    @include media-breakpoint-up(md) {
      font-size: 3.2em;
    }
  }
}

.ywmobile-bg {
  &::before {
    content: 'ywmobile';
    text-transform: uppercase;
    height: 1px;
    width: 1px;
    color: $ywm-blue-color-3;
    font-family: "Noto Sans CJ Kkr Black", sans-serif;
    font-size: 5em;
    font-weight: 400;
    top: 35%;
    @extend %flex-context-full-support;
    align-items: center;
    left: 0.4375rem;
    position: relative;

    @include media-breakpoint-up(md) {
      font-size: 11rem;
    }
  }
}

#header-careers {
  background-color: $ywm-blue-color-2;
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-image: url("/assets/images/views/landing/ywm/bg-yw-header.png");
}

.reasons-wrapper {
  .reason-item {
    @extend %flex-context-full-support;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: em(15);

    &:nth-child(1) {
      padding-left: 0;
    }

    .reason-info {
      align-self: flex-start;
      border-left: 2px solid $ywm-blue-color-2;
      padding-left: rem(13);
      padding-right: rem(46);
      margin-bottom: rem(77);

      &:nth-last-child(1) {
        padding-right: 0;
      }
    }

    & > img {
      @include media-breakpoint-up(xl) {
        padding-right: em(75);
      }
      z-index: 2;
    }
  }

  .ywm-bg-blue {
    position: absolute;
    height: 27%;
    width: 100%;
    bottom: 0;
  }
}

#section-open-positions {
  .job-list {
    .job-item {
      @extend %flex-context-full-support;
      justify-content: space-between;
      border-bottom: 2px solid $ywm-blue-color-2;
      padding: (30px / $base-font-size) + 0rem 0;
      margin-bottom: (30px / $base-font-size) + 0rem;

      & > .job-location {
        @extend %flex-context-full-support;
        align-items: center;
        color: $ywm-black-color;
        font-size: $ywm-default-font-size;
        font-weight: 400;
        line-height: 36px;
        text-transform: uppercase;

        &::after {
          content: '';
          width: 2px;
          right: 0;
          position: absolute;
          @extend %flex-context-full-support;
          height: 53%;
          background-color: $ywm-black-color;
          border-right: 2px solid $ywm-black-color;
        }
      }

      & > .job-title {
        @extend %flex-context-full-support;
        align-items: center;
        padding-left: $ywm-40-px;
        color: $ywm-blue-color-2;
        font-size: $ywm-27-px;
        font-weight: 400;
        line-height: (36px / $base-font-size) + 0rem;
        text-transform: uppercase;
      }

      & > .job-redirect-ic {
        @extend %flex-context-full-support;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

#header-career-detail {
  .ywmobile-bg {
    &::before {
      top: (32px / $base-font-size) + 0rem;
      left: 0;
    }
  }

  .ywm-header-title {
    text-transform: uppercase;
  }

  .job-description-wrapper {
    p {
      line-height: em(36, 22);
      font-size: (22px / $base-font-size) + 0rem;
    }
  }

  .scroll-down {
    @extend %flex-context-full-support;
    flex-basis: 50%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    .job-address {
      @extend %flex-context-full-support;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: $ywm-18-px;
      width: 50%;
    }

    .scroller {
      width: 50%;
      @extend %flex-context-full-support;
      align-items: flex-start;

      img {
        padding-left: 10px;
      }
    }
  }
}

#job-description-section {
  padding-top: (130px / $base-font-size) + 0rem;
  padding-bottom: 0;
}

#section-apply {
  padding-top: (200px / $base-font-size) + 0rem;
  padding-bottom: (130px / $base-font-size) + 0rem;

  #apply-form {
    padding-top: (100px / $base-font-size) + 0rem;
  }

  .add-file-wrapper {
    @extend %flex-context-full-support;
    flex-wrap: wrap;
    align-items: center;
  }
}

//#endregion

//#region ======= Partners Page - start =======
.bg-driver-partner {
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/driver-partner.jpg");
}

#business-content, #driver-content {
  display: none;
}

#header-partners {
  //display: flex;
  //justify-content: center;
  overflow: hidden;
  min-width: 360px;
  min-height: 640px;

  .scroll-down {
    display: none;
  }

  .bg-business-partner, .bg-driver-partner {
    #btn-next, #btn-prev {
      display: none;
    }

    &.minimize {
      &::before {
        z-index: 2;
        cursor: pointer;
      }

      #btn-next {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-right: em(20);
        width: rem(66);
        height: rem(66);
        background-color: #3f3b37;
        opacity: 1;
        z-index: 3;

        &:hover {
          opacity: 0.5;
        }

        // &::after {
        //   font-size: rem(16);
        //   content: '';
        //   position: relative;
        //   display: block;
        //   height: em(48);
        //   @extend %default-prototype-bg;
        //   background-image: url("/assets/images/views/landing/ywm/icons/ic-next.png");
        //   background-size: contain;
        // }
      }

      #btn-prev {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: em(20);
        width: rem(66);
        height: rem(66);
        background-color: #3f3b37;
        opacity: 1;
        z-index: 3;

        &:hover {
          opacity: 0.5;
        }

        // &::after {
        //   font-size: rem(16);
        //   content: '';
        //   position: relative;
        //   display: block;
        //   height: em(48);
        //   @extend %default-prototype-bg;
        //   background-image: url("/assets/images/views/landing/ywm/icons/ic-prev.png");
        //   background-size: contain;
        // }
      }
    }
  }

  .bg-business-partner {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/business-partner.jpg");
    @extend %flex-context-full-support;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: absolute;
    background-blend-mode: multiply;
    left: -50%;
    top: 0;
    transition: all 1s ease;

    &.minimize {
      left: -70%;
      @include media-breakpoint-up(lg) {
        left: -86%;
      }

      &::before {
        z-index: 2;
      }
    }

    &.fully {
      left: -30%;
      @include media-breakpoint-up(lg) {
        left: -14%;
      }
    }
  }

  .bg-driver-partner {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/driver-partner.jpg");
    @extend %flex-context-full-support;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: absolute;
    background-blend-mode: multiply;
    top: 0;
    right: -50%;
    transition: all 1s ease;

    &.minimize {
      right: -70%;
      @include media-breakpoint-up(lg) {
        right: -86%;
      }

      &::before {
        z-index: 2;
      }
    }

    &.fully {
      right: -30%;
      @include media-breakpoint-up(lg) {
        right: -14%
      }
    }
  }

  .business-wrapper, .driver-wrapper {
    //@extend %flex-context-full-support;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    //align-content: center;
    //flex-wrap: wrap;
    color: $ywm-white-color;

    .ywm-header-title {
      line-height: em(20);
      color: $ywm-white-color;
    }

    .slash {
      font-size: rem(18);
      line-height: em(30, 18);
      padding-top: em(58, 18);
    }

    .scroll-down {
      position: absolute;
      bottom: 2%;
    }
  }

  .business-wrapper {
    @media screen and (min-width: $lg) {
      padding-right: em(100);
    }

    .scroll-down {
      left: 60%;
    }
  }

  .driver-wrapper {
    @media screen and (min-width: $lg) {
      padding-left: em(100);
    }
  }

  .scroll-down {
    left: 40%;
  }
}

#business-section {
  .business-wrapper {
    .business-item {
      margin-bottom: rem(105);

      .business-info {
        @extend %flex-context-full-support;
        align-items: center;
        justify-content: center;
        max-width: 630px;
        margin: auto;
        z-index: 1;

        @include media-breakpoint-up(md) {
          top: rem(-50);
        }

        @include media-breakpoint-up(lg) {
          top: 0;
          justify-content: flex-start;
        }

        & > .card {
          font-size: rem(16);

          @include media-breakpoint-up(lg) {
            left: -43%;
          }

          & > .card-body {
            padding: em(16) em(16);

            @include media-breakpoint-up(lg) {
              padding: em(57) em(72);
            }
          }
        }

        &.order-lg-first {
          & > .card {
            @include media-breakpoint-up(lg) {
              left: 43%;
            }
          }
        }

        .business-title {
          line-height: em(34, 27);
        }

        .business-description {
          font-size: em(16);
          line-height: em(24, 16);
        }
      }

      .business-img-wrapper {
        @extend %flex-context-full-support;
        justify-content: center;
        align-items: flex-start;

        @include media-breakpoint-up(lg) {
          justify-content: flex-start;
        }
      }
    }
  }
}

#our-partner-section {
  height: auto;
  width: 100%;
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/bg-our-partners.jpg");

  .our-partner-wrapper {
    .ywm-title {
      text-transform: uppercase;
    }
  }

  .partners-logo-wrapper {
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-content: center;
    align-content: center;

    .partner-logo {
      @extend %flex-context-full-support;
      justify-content: center;
      align-items: flex-end;
      min-height: 100px;
      text-align: center;
      margin-bottom: em(123);
    }
  }
}

#driver-reasons-section {
  .driver-reasons-wrapper {

    .reason-item {
      padding: 0 em(52);

      &:nth-child(1), &:nth-last-child(1) {
        &::after {
          content: none;
        }

        &::before {
          content: none;
        }
      }

      &::after {
        content: '';
        width: 2px;
        height: 64.7%;
        position: absolute;
        display: block;
        right: 0;
        top: 16%;
        background-color: $ywm-white-color-2;
      }

      &::before {
        content: '';
        width: 2px;
        height: 64.7%;
        position: absolute;
        display: block;
        left: 0;
        top: 16%;
        background-color: $ywm-white-color-2;
      }

      .reason-img {
        max-width: 290px;
        min-height: 230px;

        & > img {
          object-fit: contain;
        }
      }

      .reason-desc {
        text-align: center;

        & > p {
          font-size: rem(16);
          line-height: em(24, 16);
        }
      }
    }
  }
}

.ywm-step-horizontal {
  @extend %flex-context-full-support;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;

  @include media-breakpoint-up(lg) {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .step-item {
    padding: 3rem 0;
    height: auto;
    min-height: 220px;
    margin: 0 rem(16);

    &:first-child {
      padding-top: 0;
    }

    @extend %flex-context-full-support;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    min-width: 100%;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1 1 25%;
      -webkit-flex: 1 1 25%;
      flex: 1 1 25%;
      min-width: 25%;
      padding: rem(16) 0;
    }

    .step-icon {
      -ms-flex-negative: 0;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      font-size: rem(48);
      top: 0;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;

      @include media-breakpoint-up(lg) {
        top: em(-60, 48);
      }

      .iconbox {
        background-color: $ywm-white-color;
        color: $ywm-blue-color-2;
        border: 1px solid $ywm-blue-color-2;
        font-size: rem(48);
        padding: em(42, 48) em(42, 48);
        font-weight: 700;
        margin: auto;
      }

      &::before {
        content: none;
      }
    }

    .step-content {
      font-size: rem(16);
      text-align: left;
      width: 100%;
      margin-left: 0;

      @include media-breakpoint-up(lg) {
        text-align: center;
      }

      .step-title {
        font-weight: 400;
        color: $ywm-blue-color-2;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    margin-left: -1px;
    background-color: #50a1ff;
    bottom: auto;
    width: 2px;
    height: 80%;
    right: 0;
    left: em(68, 17);
    top: em(22, 17);

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
    }
  }
}

#driver-content {
  .section {
    padding: em(130) 0;
  }

  #how-to-sign-up {
    font-size: rem(22);
  }

  #your-challenge {
    @extend %default-prototype-bg;
    background-image: url("/assets/images/views/landing/ywm/icons/star-logo.svg");
    background-size: auto;
    background-position: right bottom;

    #download-app {
      font-size: rem(27, 16);
      margin-top: em(160, 27);

      & > a {
        max-width: 270px;
      }
    }

    #support-description {
      font-size: rem(22, 16);

      & > p {
        line-height: em(70, 22);
      }
    }
  }

  #star-logo-img {
    position: absolute;
    @extend %flex-context-full-support;
    right: 0;
    bottom: 0;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: rem(151, 16);
  }
}

//#endregion

//#region ======= Become Driver =======
#header-become-driver {
  @media screen and (max-width: 1200px) {
    height: auto !important;
  }
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/driver-partner.jpg");

  #register-driver-form {
    background-color: rgba(255, 255, 255, .95);
  }
}

#or-divider {
  margin: 2rem auto;
  @include media-breakpoint-up(xl) {
    margin: 0 1rem;
  }

  &:before {
    content: '';
    border-radius: 10px;
    display: inline-flex;
    width: 15vh;
    height: 2px;
    align-self: center;
    margin: 0.25rem auto;
    background-color: $ywm-white-color;

    @include media-breakpoint-up(xl) {
      width: 2px;
      height: 10vh;
      display: block;
    }
  }

  &:after {
    content: '';
    border-radius: 10px;
    display: inline-flex;
    width: 15vh;
    height: 2px;
    align-self: center;
    margin: 0.25rem auto;
    background-color: $ywm-white-color;

    @include media-breakpoint-up(xl) {
      width: 2px;
      height: 10vh;
      display: block;
    }
  }

}

//#endregion

//#region ======= Tutorial Register Driver On App =======
#tutorial-register-driver-header {
  height: auto;
  min-height: 718px;
}

.ywm-mgscroll-wrapper {
  .ywm-mgscroll-item {
    height: auto;
    padding: 1rem 1rem;
  }
}

#tutorial-wrapper {
  .ywm-mgscroll-item {
    margin: rem(50);
    @include media-breakpoint-down(sm) {
      margin: rem(16);
    }

    .tutorial-header {
      .tutorial-title {
        font-weight: bold;
      }

      hr {
        border-top-width: 2px;
        width: 45%;

        &:after {
          content: '';
          display: block;
          width: 20%;
          border-top: 2px solid $ywm-blue-color-2;
          margin: auto;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}

#steps-side-bar {
  .nav {
    position: relative;

    @include media-breakpoint-up(xl) {
      position: fixed;
    }

    & > .nav-link {
      width: 100%;
    }
  }
}

#header-tutorial-register-driver {
  height: auto;
  min-height: 718px;
  padding-top: 100px;
  padding-bottom: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 90px;
  }

  #tutorial-des-section {
    padding-top: rem(194);

    h1 {
      font-size: rem(40);
      line-height: em(60, 40);
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      font-size: rem(18);
      line-height: em(32, 18);
      color: $ywm-black-color-2;
      padding-top: em(18, 18);
    }
  }

  #tutorial-bg-wrapper {
    height: 100%;
    min-height: 718px;

    @extend %default-prototype-bg;
    background-position: right center;
    background-size: contain;
    background-image: url("/assets/images/views/landing/ywm/register-driver-header.png");

    &:before {
      content: '';
      display: block;
      width: 100%;
      max-width: 136px;
      top: calc(100% - 280px);
      height: 100%;
      max-height: 56px;
      left: -96px;
      position: absolute;
      background-image: url(/assets/images/views/landing/ywm/icons/v_let_play_video.svg);
    }

    #btn-play-video {
      background: rgba(50, 162, 242, 0.8);
      outline: none;
      border: none;
      top: 55.99%;
      height: 92px;
      left: 8%;
      position: absolute;
      width: 92px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.07);

      &:hover {
        color: $ywm-white-color;
        transform: scale(1.075);
      }
    }
  }
}

.tutorial-register-next-step {
  font-size: rem(14);
  line-height: em(19, 14);
  letter-spacing: em(1.16667, 14);
  text-transform: lowercase;
  color: $ywm-blue-color-2;
  font-weight: normal;
  padding-bottom: em(60, 14);
}

#tutorial-register-main {
  .tutorial-step-section {
    min-height: 809px;
  }

  .tutorial-step-wrapper {
    height: auto;
    min-height: 809px;
    align-items: center;

    .tutorial-step-content {
      text-align: justify;
      //padding-left: rem(56);
      font-weight: normal;

      &-left {
        padding-left: 0;
        padding-right: rem(90);
      }

      .tutorial-step-title {
        font-weight: bold;
        font-size: rem(32);
        line-height: em(44, 32);
        color: $ywm-blue-color-2;
      }

      p {
        font-size: rem(18);
        line-height: em(32, 18);
      }

      ul {
        font-size: rem(18);
        line-height: em(32, 18);
        font-weight: normal;
        padding-inline-start: 20px;
      }
    }
  }

  .bg-step {
    position: absolute;
    background-size: contain;
    min-height: 809px;
    width: 100%;

    &-left {
      background-position: left top;
      left: 0;
    }

    &-right {
      background-position: right top;
      right: 0;
    }
  }

  #tutorial-video-section {
    .tutorial-video-wrapper {
      justify-content: center;
      align-content: center;

      .tutorial-video-title {
        font-weight: bold;
        font-size: rem(32);
        line-height: em(44, 32);
        color: $ywm-blue-color-2;
      }

      .video-wrapper {
        max-width: 910px;
        margin: rem(48) auto rem(134);
      }
    }
  }
}

.bg-bean {
  background-image: url("/assets/images/views/landing/ywm/bean-bg.png");
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

//#endregion
.swiper-container {
  width: 100%;
  height: auto;

  .swiper-wrapper-center {
    .swiper-slide {
      @extend %flex-context-full-support;
      margin: auto;

      img {
        margin: auto;
      }
    }
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #f0f0f0;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #f6db22;
}

.grecaptcha-responsive {
  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.ywm_ic_play_video {
  &:after {
    content: '';
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background-image: url("/assets/images/views/landing/ywm/icons/ic_play_video.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 75% center;
  }
}

.ywm_ic_arrow_down {
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/icons/ic_arrow_down.svg");
  display: block;
  position: relative;
  font-size: rem(16);
  width: em(16);
  height: em(28);
}

.ywm_ic_arrow_next {
  @extend %default-prototype-bg;
  background-image: url("/assets/images/views/landing/ywm/icons/ic_arrow_next.svg");
  background-size: contain;
  display: block;
  position: relative;
  font-size: rem(24);
  width: em(24, 24);
  height: em(10, 24);
}

.oembed {
  width: 100vh;
}

/*======= Responsive - start ======= */
@import "responsive";
/*======= Responsive - end ======= */